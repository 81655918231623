import React from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Paragraph from '../components/Paragraph'

const NotFoundPage = () => (
  <Layout>
    <Seo
      title="404: Not found"
      meta={[
        {
          name: `robots`,
          content: `noindex`,
        },
      ]}
    />
    <Flex>
      <Lead>Oops! There&apos;s nothing here.</Lead>
      <Paragraph>
        Maybe you were looking for the <Link to="/">home page</Link> or the{' '}
        <Link to="/blog">blog</Link>?
      </Paragraph>
    </Flex>
  </Layout>
)

export default NotFoundPage

const Flex = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
`

const Lead = styled.h1`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 400;
  font-size: 2rem;
  margin: 0;
  text-align: center;
  @media (min-width: 450px) {
    font-size: 3rem;
  }
`

const Link = styled(GatsbyLink)`
  text-decoration: underline;
`
